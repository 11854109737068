import styled from 'styled-components'
import { DEVICE } from '../../util/constants'
import { HeroH1, HeroP } from '../styles/Utility.styles'

export const HeroTitle = styled(HeroH1)`
  grid-column: 2 / 3;
  padding: 40px 0 14px;

  @media ${DEVICE.tabletL} {
    padding: 50px 0 20px;
  }

  @media ${DEVICE.laptopG} {
    width: calc(100% - 378px);
  }
`

export const HeroBody = styled(HeroP)`
  grid-column: 2 / 3;

  @media ${DEVICE.laptopG} {
    grid-column: span 3;
  }
`

export const MediaLibraryFeaturedTitle = styled.h2`
  grid-column: 2 / 3;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 1.35;
  color: var(--highlight-blue);
  padding-top: 38px;

  @media ${DEVICE.tabletL} {
    font-size: 35px;
  }

  [data-layout='two-column'] & {
    @media ${DEVICE.laptopG} {
      grid-column: span 3;
    }
  }
`

export const MediaLibrarySectionTitle = styled.h2`
  grid-column: 2 / 3;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 1.35;
  color: var(--highlight-blue);
  padding-top: 38px;

  @media ${DEVICE.tabletL} {
    font-size: 48px;
    padding-top: 50px;
  }

  @media ${DEVICE.laptopG} {
    padding-top: 75px;
  }

  [data-layout='two-column'] & {
    @media ${DEVICE.laptopG} {
      grid-column: span 3;
    }
  }
`

export const ItemsGridContainer = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`

export const FilterIndicatorBar = styled.div`
  grid-column: 2 / 3;
  height: max-content;
  background: var(--content-background-dark-blue);
  display: ${({ isEnabled }) => (isEnabled ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: 25px;
  align-items: center;

  @media ${DEVICE.tabletL} {
    height: 70px;
    flex-direction: row;
  }
`

export const ShowingResultsFor = styled.span`
  font-size: 2rem;
  line-height: 1.65;
  color: var(--graphic-accent-blue-2);
  padding: 0 15px 0 18px;

  @media ${DEVICE.tabletL} {
    font-size: 2.4rem;
  }
`

export const FilterIndicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;
`

export const CloseXContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`

export const FilterIndicatorText = styled.span`
  font-size: 2rem;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: white;
  margin-right: 10px;

  @media ${DEVICE.tabletL} {
    font-size: 2.4rem;
  }
`

export const FilterIndicatorCloseButton = styled.button`
  /* naming convenience */
`
