import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'
import { Link } from 'gatsby'

export const HighlightRegion = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  padding-top: 12px;

  [data-layout='two-column'] & {
    @media ${DEVICE.laptopG} {
      grid-column: span 3;
    }
  }
`

export const HighlightMediaItemImage = styled.picture`
  img {
    width: 100%;
    height: auto;
  }
`

export const HighlightInfoColumn = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 25px 0 0;
  text-decoration-line: none;
`

export const MediaItemMetadataRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`

export const MetadataTextRegion = styled.div`
  color: var(--highlight-blue);
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  padding-left: 25px;
`

export const MetadataTitle = styled.h3`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  span {
    color: var(--content-background-dark-blue);
  }
`

export const MetadataDate = styled.h4`
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
`

export const HighlightMediaTitle = styled.h2`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 1.375;
  color: var(--content-background-dark-blue);
  padding-top: 16px;

  @media ${DEVICE.tabletL} {
    font-size: 2.8rem;
  }
`

export const MediaItemColumn = styled(Link)`
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 0 0;
  text-decoration-line: none;

  @media screen and (min-width: 450px) {
    padding-right: 33px;
    flex: 0 0 50%;

    :nth-of-type(even) {
      padding-right: 0;
    }
  }

  @media screen and (min-width: 900px) {
    flex: 0 0 33%;

    :nth-of-type(even) {
      padding-right: 33px;
    }
  }
`

export const MediaItemIconImageRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 166px;
  padding-bottom: 16px;

  img {
    border-radius: 0;
  }
`

export const MediaItemTopic = styled.h3`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--highlight-blue);

  /* span for *CME callout */
  span {
    color: var(--content-background-dark-blue);
  }
`

export const MediaItemDate = styled.h4`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  color: var(--highlight-blue);
`

export const MediaItemCredits = styled.h5`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  color: var(--content-background-dark-blue);
`

export const MediaItemTitle = styled.span`
  h1 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 1.375;
    color: var(--content-background-dark-blue);
    padding-top: 6px;

    @media ${DEVICE.tabletL} {
      font-size: 2.4rem;
    }
  }
`
